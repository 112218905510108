import UtilsComponent from '../components/utils.component'

export default class HomeService {
    swipers = []
    speed = 1000
    isEcomode = IRISCollectionCustomer.isEcomodeActive
    isMobile = window.matchMedia('(max-width: 1024px)').matches

    constructor() {
        this.initSectionInspirations()

        if (!this.isEcomode) {
            this.initSectionDestination()
            this.initSectionEssentials()
            this.initSectionEvents()
            this.initSectionStay()
            UtilsComponent.initCustomerScrollReveal()
        }
    }

    async initSectionDestination() {
        if (this.isMobile) {
            const swiper = (this.swipers.destination = UtilsComponent.customerSlider(
                'destination',
                {
                    slidesPerView: 'auto',
                },
            ))
        } else {
            const nbitems = $('[data-zone]').length
            const zone = $('[data-zone]').first().data('zone')
            $(`#${zone}`).addClass('is-active')
            $(`[data-customer-slider="destination"]`).attr('dir', 'rtl')

            await UtilsComponent.customerSlider(
                'destination',
                {
                    slidesPerView: 'auto',
                    on: {
                        slideChange: (e) => {
                            const zone = $('[data-zone]').eq(e.activeIndex).data('zone')
                            $('#home-interactive-map #zones path').removeClass('is-active')
                            $(`#${zone}`).addClass('is-active')
                        },
                    },
                },
                '#section-destination .section__footer',
            ).then((swiper) => {
                $('#home-interactive-map #zones path').on('click', function () {
                    const id = $(this).attr('id')
                    const index = $(`[data-zone="${id}"]`).index()
                    swiper.slideTo(index)
                })
            })
        }
    }

    initSectionInspirations() {
        $('[data-customer-ajax-trigger]').on('click', function () {
            if (!$(this).hasClass('is-active')) {
                const button = this

                const postsWrapper = $(this).closest('.section').find('[data-customer-ajax-posts]')
                const key = $(this).data('customer-ajax-trigger')

                $(postsWrapper).addClass('is-loading')

                $.ajax({
                    url: IRISCollectionTheme.ajaxUrl,
                    type: 'post',
                    data: {
                        action: 'get_posts',
                        key: key,
                    },
                    success(data) {
                        console.log(data)
                        $('[data-customer-ajax-trigger]').removeClass('is-active')
                        $(button).addClass('is-active')

                        const results = JSON.parse(data)

                        if (results.posts.length > 0) {
                            $(postsWrapper).html(results.posts)
                        }

                        setTimeout(() => {
                            $(postsWrapper).removeClass('is-loading')
                        }, 250)
                    },
                    error(error) {
                        $(postsWrapper).removeClass('is-loading')
                    },
                })
            }
        })
    }

    async initSectionEssentials() {
        const swiperconfig = !this.isMobile
            ? {
                autoplay: {
                    delay: 2500,
                },
                effect: 'fade',
                fadeEffect: {
                    crossFade: true,
                },
                on: {
                    slideChange: (e) => {
                        $('[data-trigger="essentials"]').removeClass('is-active')
                        $('[data-trigger="essentials"]')
                            .eq(e.realIndex)
                            .addClass('is-active')
                    },
                },
            }
            : {
                slidesPerView: 'auto',
                centeredSlides: true,
                loop: true,
                autoplay: {
                    delay: 2500,
                },
            }

        await UtilsComponent.customerSlider(
            'essentials',
            swiperconfig,
        ).then((swiper) => {
            let animationReady = true
            let timeoutFn = null
            const swiperInstance = swiper;

            $('[data-trigger="essentials"]').on('mouseover', (e) => {
                const index = $(e.target).closest('li').index()

                if (animationReady) {
                    clearTimeout(timeoutFn)
                    timeoutFn = setTimeout(() => {
                        $('[data-trigger="essentials"]').removeClass('is-active')
                        animationReady = false
                        swiperInstance.slideTo(index)
                        $(e.target).addClass('is-active')
                        animationReady = true
                    }, 100)
                }
            })
        })
    }

    initSectionEvents() {
        this.swipers.events = UtilsComponent.customerSlider(
            'events',
            {
                slidesPerView: 'auto',
            },
            !this.isMobile ? '#section-events .section__heading' : '',
        )
    }

    initSectionStay() {
        UtilsComponent.customerSlider('stay', {
            mousewheel: false,
            allowMouseWheel: false,
            allowTouchMove: false,
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            },
        }).then((swiper) => {
            UtilsComponent.customerSlider(
                'stay-list',
                {
                    slidesPerView: 'auto',
                    controller: {
                        control: swiper,
                    },
                },
                !this.isMobile ? '#section-stay .section__footer' : '',
            ).then();
        });
    }
}
